import React from "react"

const SolutionsHeader = () => {
  return (
    <div className="pt-12 md:pt-10 px-4 bg-base-200 textLight">
      <div className="text-center">
      <p className="py-4 text-sm uppercase font-semibold sm:text-md">everything you can do with</p>
        <h1 className="text-2xl tracking-tight font-extrabold sm:text-4xl ">
          Customisable Inspection Reports
        </h1>
      </div>
    </div>
  )
}
export default SolutionsHeader
