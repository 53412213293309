import React from "react"

import Step1 from "../images/step1.png"
import Step2 from "../images/step2.png"
import Step3 from "../images/step3.png"

const Process = ({ title, content }) => {
  return (
    <div className="bg-base-200 text-blueHighlight">
      <div className="max-w-7xl mx-auto py-4 sm:pb-24 px-4 text-center sm:px-6 lg:px-8 lg:py-12 lg:pb-16">
        <div className="space-y-12">
          <ul className="mx-auto grid-cols-2 space-y-16 sm:grid sm:grid-cols-3 sm:gap-16 sm:space-y-0 sm:max-w-7xl lg:grid-cols-3 lg:max-w-5xl">
            <li>
              <div className="space-y-6">
                <img className="mx-auto h-20 text-centre" src={Step1} alt="checkbox icon" />
                <div className="space-y-2">
                  <div className="text-sm mt-4 text-primary font-extrabold space-y-1 uppercase text-centre">
                    <p>step 1</p>
                  </div>
                  <div className="text-lg text-ivoryMain font-semibold space-y-1 uppercase text-centre">
                    <h3>check boxes</h3>
                  </div>
                  <div className="text-sm pt-2 font-light text-ivoryMain leading-tight text-justify lg:text-center space-y-12 px-8 sm:px-2 md:px-4">
                    <h3>
                      Create custom reports to suit any property type. Add
                      unique rooms and items, re-use tenant data and much more.
                      Inspect Live gives you the control.
                    </h3>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="space-y-6">
                <img className="mx-auto h-20 text-centre" src={Step2} alt="upload icon" />
                <div className="space-y-2">
                  <div className="text-sm mt-4 text-primary font-extrabold space-y-1 uppercase text-centre">
                    <p>step 2</p>
                  </div>
                  <div className="text-lg text-ivoryMain font-semibold space-y-1 uppercase text-centre">
                    <h3>insert photos</h3>
                  </div>
                  <div className="text-sm pt-2 font-light text-ivoryMain leading-tight text-justify lg:text-center space-y-12 px-8 sm:px-2 md:px-4">
                    <h3>
                      Create custom reports to suit any property type. Add
                      unique rooms and items, re-use tenant data and much more.
                      Inspect Live gives you the control.
                    </h3>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="space-y-6">
                <img className="mx-auto h-20 text-centre" src={Step3} alt="save icon" />
                <div className="space-y-2">
                  <div className="text-sm mt-4 text-primary font-extrabold space-y-1 uppercase text-centre">
                    <p>step 3</p>
                  </div>
                  <div className="text-lg text-ivoryMain font-semibold space-y-1 uppercase text-centre">
                    <h3>press save</h3>
                  </div>
                  <div className="text-sm pt-2 font-light text-ivoryMain leading-tight text-justify lg:text-center space-y-12 px-8 sm:px-2 md:px-4">
                    <h3>
                      Create custom reports to suit any property type. Add
                      unique rooms and items, re-use tenant data and much more.
                      Inspect Live gives you the control.
                    </h3>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Process
