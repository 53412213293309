import React from "react"

const Header = ({ intro, Heading, Heading2, subHeading }) => {
  return (
    <div>
      <div className="hero sm:pt-8 bg-base-200 textLight">
        <div className="hero-content text-center max-w-7xl">
          <div className="">
            <p className="py-4 text-sm uppercase font-semibold sm:text-md">{intro}</p>
            <h1 className="text-2xl font-bold capitalize sm:text-4xl">
              {Heading}
            </h1>
            <h1 className="text-2xl font-bold capitalize sm:text-4xl">
              {Heading2}
            </h1>
            <p className="mx-auto mt-6 mx-w-xl md:max-w-2xl text-base text-gray-200 text-center px-4 sm:text-lg md:mt-5 md:text-xl leading-tight">
              {subHeading}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
