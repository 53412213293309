import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Header from "../components/hero"
import Logos from "../components/logo-cloud"
import Heading from "../components/header"
import Testimonial from "../components/testimonial"
import FeaturesHeading from "../components/header"
import Features from "../components/features"
import Process from "../components/process"
import Cta from "../components/cta"
import SolutionsHeader from "../components/solutions-header"
import Solutions from "../components/solutions"
import Faqs from "../components/faqs"
import Cta2 from "../components/cta"

import Customer1 from "../images/customer1.png"
import Customer2 from "../images/customer2.png"

const IndexPage = () => (
  <Layout>
    <Seo title="Property Inspection for Australian Property Managers" />
    <Header />
    <Logos />
    <Heading
      Heading="If you're a Property Manager,"
      Heading2="you'll love Inspect Live!"
    />
    <Testimonial
      customerStoryImg={Customer1}
      name="Amal Khodair-Vemana"
      quote="I always try to be fair to both parties. That’s why it’s important to capture everything in the report - from walls and carpets to door knobs and toilet seats. Inspect Live allows me to do that, and helps prevent any future disputes between owner and tenant. I love it! But more importantly, owners and tenants love it."
      role="General Manager (Amkar Real Estate, Adelaide)"
    />
    <FeaturesHeading
      Heading="Say hello to the easiest way"
      Heading2="to conduct your inspection reports."
      subHeading="Hate manually filling out property inspection sheets? Save time and energy by selling properties faster & smarter with Inspect Live."
    />
    <Features />
    <Cta
      header="Get full control over the property inspection report process. Simple, powerful and easy to use, make inspections a breeze."
      content="Start your FREE* Trial Today!"
      terms="* Free one month trial is activated when user logs into Inspect Live with their REI Forms Live/Realworks credentials. 
      Free trial can only be activated once per Agency account. To review trial access initiation date, see Addons in your REI Forms Live/Realworks Account Details area (principal users only)."
    />
    <Heading Heading="Customer Stories" />
    <Testimonial
      standard
      customerStoryImg={Customer2}
      name="Tina Case"
      quote="As a Property Manager, I’m out of the office quite a bit, so portability is a big thing for me - being able to do my ‘paperwork’ while at a property is a great time-save. Using Inspect Live to do an inspection report on my phone or tablet is fabulous - it remembers my key phrases, so I don’t have to retype every comment. I can also make changes to a report after I’ve closed the app…it’s not locked. Put Simply, I’m much more productive and the landlords love it."
      role="Property Manager (The Professionals Group, Lithgow)"
    />

    <Heading
      intro="Reports to Suit Any Property"
      Heading="It's as easy as 1, 2, 3"
      subHeading="You’re a lot closer to building and reusing custom reports to match any architectural layout than you think."
    />
    <Process />
    <SolutionsHeader />
    <Solutions />
    <Cta2
      dark
      intro="download now"
      header="It's time for improved, efficient inspections that save you time, money – all you need is a phone or tablet and you're away!"
      content="Start your FREE* Trial Today!"
      terms="* Free one month trial is activated when user logs into Inspect Live with their REI Forms Live/Realworks credentials. 
    Free trial can only be activated once per Agency account. To review trial access initiation date, see Addons in your REI Forms Live/Realworks Account Details area (principal users only)."
    />
    <Faqs />
  </Layout>
)

export default IndexPage
