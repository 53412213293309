import React from "react"

import Fast from "../images/fast_reliable1.png"
import Powerful from "../images/powerful_flexible1.png"
import Offline from "../images/offline_first1.png"
import Seamless from "../images/seamless_integration1.png"
import Compliant from "../images/legally_compliant1.png"
import Support from "../images/support_feedback1.png"

const Solutions = () => {
  return (
    <div id="features" className="py-12 md:pt-36 lg:py-12 lg:pt-40">
      <div className="bg-base-200 text-blueHighlight">
        <div className="max-w-5xl mx-auto  px-4 text-center sm:px-6 lg:px-8 ">
          <div className="space-y-12 lg:col-span-2 pb-12">
            <ul className="grid grid-cols-2 gap-y-12 gap-x-8 md:grid-cols-3 lg:grid-cols-3 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
              <li>
                <div className="space-y-6">
                  <img
                    className="mx-auto h-24 xl:w-46 xl:h-46 text-centre"
                    src={Fast}
                    alt="mobile icon with a finger dragging and dropping blocks onto the screen."
                  />
                  <div className="space-y-2">
                    <div className="text-sm mt-8 leading-6 font-bold space-y-1 uppercase text-left md:px-2 lg:text-center text-primary">
                      <h3>fast & reliable</h3>
                    </div>
                    <div className="text-xs md:text-sm font-light textLight text-left lg:text-center space-y-12 md:px-2 lg:px-8 leading-tight">
                      <h3>
                        Inspect Live helps manage the inspection process from
                        start to finish. Create customised visual reports in a
                        matter of minutes.
                      </h3>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="space-y-6">
                  <img
                    className="mx-auto h-24 xl:w-46 xl:h-46 text-left"
                    src={Powerful}
                    alt="mobile phone with a person adding a tick badge to a completed form."
                  />
                  <div className="space-y-2">
                    <div className="text-sm mt-8 leading-6 font-bold space-y-1 uppercase text-left lg:text-center md:px-2 text-primary">
                      <h3>powerful & flexible</h3>
                    </div>
                    <div className="text-xs md:text-sm font-light textLight text-left lg:text-center space-y-12 md:px-2 lg:px-8 leading-tight">
                      <h3>
                        You’re in control with Inspect Live: add custom rooms,
                        record unique items, reuse or modify tenant data to
                        create reports for any property.
                      </h3>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="space-y-6">
                  <img
                    className="mx-auto h-24 xl:w-46 xl:h-46 text-centre"
                    src={Offline}
                    alt="a person holding a form with a tick badge in their hands."
                  />
                  <div className="space-y-2">
                    <div className="text-sm mt-8 leading-6 font-bold space-y-1 uppercase text-left lg:text-center md:px-2 text-primary">
                      <h3>offline first</h3>
                    </div>
                    <div className="text-xs md:text-sm font-light textLight text-left lg:text-center md:px-2 space-y-12 sm:px-12 lg:px-8 leading-tight">
                      <h3>
                        Inspect Live makes it easy to create and complete forms
                        entirely offline. Then, when it suits you, securely
                        upload your forms to REI Forms Live/Realworks.
                      </h3>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="space-y-6">
                  <img
                    className="mx-auto h-24 xl:w-46 xl:h-46 text-centre"
                    src={Seamless}
                    alt="mobile phone screen with a person adding content blocks to a form."
                  />
                  <div className="space-y-2">
                    <div className="text-sm mt-8 leading-6 font-bold space-y-1 uppercase text-left lg:text-center md:px-2 text-primary">
                      <h3>seamless integration</h3>
                    </div>
                    <div className="text-xs md:text-sm font-light textLight text-left lg:text-center md:px-2 space-y-12 sm:px-12 lg:px-8 leading-tight">
                      <h3>
                        Inspect Live integrates seamlessly with REI Forms
                        Live/Realworks subscription - the online form creation
                        tool that creates more time in your day.
                      </h3>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="space-y-6">
                  <img
                    className="mx-auto h-24 xl:w-46 xl:h-46 text-centre"
                    src={Compliant}
                    alt="hand holding a mobile with a tick displaying on the screen to show a completed form."
                  />
                  <div className="space-y-2">
                    <div className="text-sm mt-8 leading-6 font-bold space-y-1 uppercase text-left lg:text-center md:px-2 text-primary">
                      <h3>Legally compliant</h3>
                    </div>
                    <div className="text-xs md:text-sm font-light textLight text-left lg:text-center md:px-2 space-y-12 sm:px-12 lg:px-8 leading-tight">
                      <h3>
                        Inspect Live is legally compliant in all states and
                        territories, and endorsed by REINSW, REISA, REIWA,
                        REINT, REIQ and REIACT.
                      </h3>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="space-y-6">
                  <img
                    className="mx-auto h-24 xl:w-46 xl:h-46 text-centre"
                    src={Support}
                    alt="a mobile phone with a customer on the screen, talking with a customer service representative standing next to the mobile phone."
                  />
                  <div className="space-y-2">
                    <div className="text-sm mt-8 leading-6 font-bold space-y-1 uppercase text-left lg:text-center md:px-2 text-primary">
                      <h3>support & feedback</h3>
                    </div>
                    <div className="text-xs md:text-sm font-light textLight text-left lg:text-center md:px-2 space-y-12 sm:px-12 lg:px-8 leading-tight">
                      <h3>
                        Inspect Live gives you access to dedicated, responsive
                        support. We also welcome feedback to help us keep
                        Inspect Live working better for you.
                      </h3>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="space-y-12">
            <ul className="mx-auto grid-cols-2 space-y-16 sm:grid sm:grid-cols-3 sm:gap-16 sm:space-y-0 sm:max-w-7xl lg:grid-cols-3 lg:max-w-5xl"></ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Solutions
