import React from "react"

const Testimonial = ({ standard, name, quote, role, customerStoryImg }) => {
  return (
    <div className="bg-base-200 pb-12">
      <div className="max-w-3xl mx-auto text-center px-4 sm:px-6 lg:px-8">
        <div className="bg-ivoryMain px-12 py-12 rounded-3xl">
          <div className="max-w-sm mx-auto sm:max-w-none sm:flex sm:text-left">
            <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
              <div className="mx-auto w-24 sm:w-40 lg:w-48">
                <img
                  className={
                    "relative pt-4 " +
                    (standard ? "h-40 sm:h-64 sm:mt-8" : "h-32  sm:h-52")
                  }
                  src={customerStoryImg}
                  alt={`Inspect Live customer testimonial from ${name}, ${role}`}
                />
              </div>
              <div className="sm:col-span-2 pt-4 sm:pt-0 sm:px-8">
                <div className="space-y-4">
                  <div className="text-xl text-blueMain font-bold">
                    <h3>{name}</h3>
                    <p className="font-semibold text-gray-900 text-sm">
                      {role}
                    </p>
                  </div>
                  <div className="text-sm text-justify sm:text-center font-light mb-8">
                    <p className="text-neutral">
                      <em>{`"${quote}"`}</em>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
