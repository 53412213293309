import React from "react"

import Circle from "../images/circle_1.png"
import Globe from "../images/features_hero1.png"

const featuresLeft = [
  {
    name: "Supports 3 common report types",
  },
  {
    name: "Add custom rooms and items",
  },
  {
    name: "Share and email completed forms",
  },
  {
    name: "Access to dedicated support",
  },
  {
    name: "Legally Compliant",
  },
]

const featuresRight = [
  {
    name: "Sync & store on REI Forms Live",
  },
  {
    name: "Regular Updates",
  },
  {
    name: "Create Forms from existing data",
  },
  {
    name: "Take and attach photos",
  },
  {
    name: "Safe and Secure",
  },
]

const Solutions = () => {
  return (
    <div
      id="solutions"
      className="flex flex-wrap overflow-hidden bg-base-200 textLight"
    >
      {/* 2 row col */}
      <div className="my-5 px-8 w-full overflow-hidden sm:my-16 sm:px-48 sm:w-full md:my-20 md:px-5 md:w-1/3 lg:my-24 lg:px-16 lg:w-1/3 xl:my-48 xl:px-24 xl:w-1/3">
        <div className="mt-12 md:mt-12 lg:mt-16 lg:col-span-2">
          <dl className="space-y-10 gap-y-8 sm:space-y-0 sm:grid sm:grid-cols-1 sm:grid-rows-5 sm:grid-flow-col sm:gap-x-6 md:gap-y-12 lg:gap-y-16 xl:gap-y-20 lg:gap-x-8">
            {featuresLeft.map(feature => (
              <div key={feature.name} className="relative">
                <img
                  src={Circle}
                  alt="tick icon"
                  className="absolute h-6 lg:h-8"
                  aria-hidden="true"
                />
                <p className="ml-12 text-md md:text-md xl:text-lg leading-6 font-medium">
                  {feature.name}
                </p>
              </div>
            ))}
          </dl>
        </div>
      </div>

      {/* mobile hero image */}
      <div className="my-12 px-5 w-full overflow-hidden sm:my-42 sm:px-3 sm:w-full md:pt-16 md:my-42 md:px-5 md:w-1/3 lg:pt-2 lg:my-24 lg:px-5 lg:w-1/3 xl:pt-0 xl:my-24 xl:px-5 xl:w-1/3">
        <div className="w-1/2 ml-24 sm:ml-0 sm:w-full mt-8 sm:mt-12 md:mt-6 lg:w-full overflow-hidden">
          <img src={Globe} alt="a person juggling forms inside the Inspect Live property inspection application."></img>
        </div>
      </div>

      {/* 2 row col */}
      <div className="mt-0 pb-12 px-8 w-full overflow-hidden sm:my-16 sm:px-48 sm:w-full md:my-20 md:px-5 md:w-1/3 lg:my-24 lg:px-16 lg:w-1/3 xl:my-48 xl:px-24 xl:w-1/3">
        <div className="mt-12 lg:mt-16 lg:col-span-2">
          <dl className="space-y-10 gap-y-8 sm:space-y-0 sm:grid sm:grid-cols-1 sm:grid-rows-5 sm:grid-flow-col sm:gap-x-6 md:gap-y-12 lg:gap-y-16 xl:gap-y-20 lg:gap-x-8">
            {featuresRight.map(feature => (
              <div key={feature.name} className="relative">
                <img
                  src={Circle}
                  alt="tick icon"
                  className="absolute h-6 lg:h-8"
                  aria-hidden="true"
                />
                <p className="ml-12 text-md md:text-md xl:text-lg leading-6 font-medium">
                  {feature.name}
                </p>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

export default Solutions
